const key = 'selected_branch_office'

export const getSelectedBranchOffice = (): number | null => {
  const officeId = localStorage.getItem(key)
  if (officeId) {
    return parseInt(officeId)
  }
  return null
}

export const setSelectedBranchOffice = (officeId: number): void => {
  localStorage.setItem(key, officeId.toString())
}
